@tailwind base;
@tailwind components;
@tailwind utilities;

/* "bg-gradient-to-tr from-[#eb2283] to-[#E95A54]" */
@font-face {
  font-family: "SUIT-Variable";
  src: url("fonts/SUIT-Variable.woff2") format("woff2");
  font-weight: regular semibold bold;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "SUIT Variable", sans-serif;
  /* overflow: hidden; */
  /* touch-action: none; */
}

.wrapper {
  @apply w-screen;
}

.minWrapper {
  @apply min-w-[380px] w-full max-w-[800px];
}

.draggable-item {
  touch-action: none;
  user-select: none;
}

/* fade-out 효과를 위한 CSS */
.fade-out {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.fade-out.hidden {
  opacity: 0;
}

/* 토스트 메세지 */
body .Toastify__toast--success {
  background-color: #eefff1;
  border: 1px solid #51cf66;
}

body .Toastify__toast--warning {
  background-color: #fff5e0;
  border: 1px solid #fab007;
}

body .Toastify__toast--error {
  background-color: #ffe9e9;
  border: 1px solid #f03f3f;
}

body .Toastify__toast--info {
  background-color: #daeeff;
  border: 1px solid #238be6;
}

body .Toastify__toast {
  border-radius: 20px;
  padding: 15px;
  font-size: 14px;
  line-height: 14px;
  color: #000;
}

/* 스크롤 바 전체에 대한 스타일 지정  */
:root {
  --main-red: #e95a54;
  --sub-red: #e95a5480;
}
::-webkit-scrollbar {
  width: 8px; /* 스크롤 바의 너비 */
  height: 8px;
  /* background-color: var(--text-white); 스크롤 바의 배경색 */
}

/* 스크롤 바의 색상 지정 */
::-webkit-scrollbar-thumb {
  background-color: var(--sub-red); /* 스크롤 바의 색상 */
  border-radius: 8px;
  background-clip: padding-box; /* 배경이 padding 영역 내에만 그려지도록 함 */
}

/* 스크롤 바 호버 시 색상 지정 */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-red); /* 스크롤 바의 색상 */
}

/* 무한 회전 */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 1s linear infinite; /* 1초마다 한 번 회전, 무한 반복 */
}
